/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const appointmentsHero = {
  title: "More clients. Fewer no-shows",
  subtext:
    "SpotOn Appointments helps you book more appointments—more efficiently—while better serving clients, streamlining your operations, and growing revenue.",
  heroImg: "appointments-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/appointments/demo",
  },
  fatTitle: "Appointments",
};

export const appointmentsTrusted = [
  {
    title: "42%",
    subtext: "Clients prefer to schedule online",
  },
  {
    title: "71%",
    subtext: "Millennials book using mobile",
  },
  {
    title: "40%",
    subtext: "Appointments are booked after hours",
  },
  {
    title: "40% ",
    subtext: "Fewer no-shows with automated reminders",
  },
];

export const appointmentsWhiteGlove = {
  mainTitle: "The help you need, every step of the way",
  title: "Grow your business with someone you can rely on",
  content:
    "SpotOn Appointments simplifies appointment booking, employee scheduling, and checkout. Equally important, we’ll help you customize it to your needs and show you how to use it. And we’ll continue to be there for you with local service and 24/7 support to help you update your software as your business adapts and grows.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/appointments/demo",
  },
};

export const appointmentsLargeFeatures = {
  sectionTitle: "40% more business. 40% fewer no-shows",
  featureBlocks: [
    {
      blockTitle: "If that sounds good, it’s because it is",
      blockImg: "appointments-a.png",
      blockSubTitle:
        "Your clients are busy. They want to book online—from their mobile phone, or after hours. Make it easy for them, while reducing no-shows, with automated email and text reminders. Then close the loop with built-in digital marketing and loyalty to keep them coming back.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/appointments/demo",
      },
    },
    {
      blockTitle: "Truly connect with your clients",
      blockImg: "appointments-b.png",
      blockList: [
        "Online booking directly from your website & Facebook business page",
        "Text & email reminders to cut down on no-shows",
        "2-way texting \n" + "with clients to keep it personal",
        "Digital marketing & loyalty \n" +
          "to attract new clients and keep regulars coming back",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/appointments/demo",
      },
    },
    {
      blockTitle: "Tools to run & protect your business",
      blockImg: "appointments-c.png",
      blockList: [
        "Interactive calendaring to manage appointments, team member schedules, services, and more through \n" +
          "one simple online dashboard",
        "No-show protection \n" + "to minimize lost sales with card-on-file",
        "Integrated payments \n" +
          "to create a smooth checkout experience with seamless deal and loyalty reward redemptions",
        "Contact tracing to comply with COVID-19 regulations",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/appointments/demo",
      },
    },
  ],
};

export const appointmentsBusinessTypesCar = {
  title: "For businesses like yours",
  subtext:
    "SpotOn Appointments is an ideal solution for any business that relies on booking client appointments. Salons and spas, personal trainers, medical service providers, repair shops, professional services—we’ve got you covered.",
  slides: [
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const appointmentsVideo = {
  title: "Don’t just send emails. Connect with your customers",
  videoBtn: "Watch video",
  posterImg: "appointments-poster.png",
  videoUrlId: "wx4JxSMsC4s",
};

export const appointmentsSolutionsSection = {
  title: " Works great with these other tools",
  subText: `SpotOn Appointments is fully integrated with the powerful customer engagement tools built-in to the SpotOn platform, making it easier than ever to drive repeat business. If you sell retail products, you can also add an optional point-of-sale to make the checkout process fast and easy.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const appointmentsTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const appointmentsIntegrations = {
  title: "Integrations.",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and appointments processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/products/appointments/demo",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept appointmentss and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const appointmentsCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/appointments/demo",
  },
};

export const appointmentsFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};
export const appointmentsSolutionsSlides = [
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Restaurants",
    link: "/restaurants",
    img: "Restaurant.png",
  },
];
